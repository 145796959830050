import { ref, Ref, onMounted, onUnmounted } from 'vue';

export function useElementWidth(element: Ref<Element>): Ref<number> {
    const width = ref(0);

    // const resizeObserver = new ResizeObserver((entries) => {
    //     width.value = entries[0].borderBoxSize[0].inlineSize;
    // });

    let interval;
    onMounted(() => {
        interval = setInterval(() => {
            width.value = element.value.clientWidth;
        }, 1000)
        //resizeObserver.observe(element.value);
    });

    onUnmounted(() => {
        clearInterval(interval);
        //resizeObserver.disconnect();
    });

    return width;
}
