import notification from "@/services/notify";
import { raygun } from "@/services/raygun";
import { refreshToken } from "@/services/refresh";
import httpGetCached from "@/services/httpGetCached";
import mobileCheck from "@/services/mobileCheck";
import throttle from "@/services/throttle";

export default {
    notification,
    raygun,
    refreshToken,
    httpGetCached,
    mobileCheck,
    throttle
}