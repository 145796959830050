import { computed, Ref, ComputedRef } from 'vue';
import { useRootFontSize } from './useRootFontSize';
import { useElementWidth } from './useElementWidth';

export function useElementRemWidth(element: Ref<Element>): ComputedRef<number> {
    const rootFontSize = useRootFontSize();
    const elementWidth = useElementWidth(element);

    const width = computed(() => {
        return elementWidth.value / rootFontSize.value;
    });

    return width;
}