import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainTemplate from "@/pages/mainTemplate.vue";
import Home from "@/pages/home.vue";
import AboutUs from "@/pages/aboutUs.vue";
import DriveWithUs from "@/pages/driveWithUs.vue";
import Carriers from "@/pages/carriers.vue";
import { nextTick } from 'vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'mainTemplate',
        component: MainTemplate,
        children: [
            {
                path: "/",
                name: "home",

                component: Home,
                meta: {
                    title: "PBS Transport"
                }
            },
            {
                path: "/about",
                name: "aboutUs",

                component: AboutUs,
                meta: {
                    title: "About Us"
                }
            },
            {
                path: "/drive",
                name: "driveWithUs",

                component: DriveWithUs,
                meta: {
                    title: "Drive With Us"
                }
            }
            ,
            {
                path: "/carriers",
                name: "carriers",

                component: Carriers,
                meta: {
                    title: "Carriers"
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '@/pages/login.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        const appElement = document.querySelector(".app-main");
        const scrollToHash = () => {
            const yOffset = -100;
            let yPos = (document.getElementById(to.hash.substring(1))?.getBoundingClientRect().top || 0) + window.pageYOffset;
            if (yPos === 0 || yPos) {
                yPos += yOffset;
            }
            appElement?.scroll({ top: yPos });
        };

        const scrollToTop = () => {
            appElement?.scroll({ top: 0 });
        };

        scrollToTop();
        if (to.hash) {
            scrollToHash();
        }
    }
})

router.beforeEach((to, from) => {
    if (to.meta?.title)
        document.title = to.meta?.title as string ?? 'PBS Transport'
})

export default router
