import {
    App,
    getCurrentInstance,
    inject,
} from "vue";
import Axios from 'axios';
import store from '@/store';

const throttle = {
    install(app: App, options = {}) {
        app.provide("$throttle", function (fn: (...args) => any, interval: number) {
            let throttled = false;
            return (...args) => {
                if (!throttled) {
                    fn(...args);
                    throttled = true;
                    setTimeout(() => {
                        throttled = false;
                        fn(...args);
                    }, interval);
                }
            };
            // const cache = await store.getters.HttpGet(url);
            // if (cache) {
            //     return JSON.parse(JSON.stringify(cache));
            // } else {
            //     const response = await Axios.get(url);
            //     store.dispatch("setGetResult", { key: url, value: response })
            //     return response;
                
            // }
        });
    }
}

export default throttle;