<template>
    <nav :class="navClasses" ref="navElement">
        <div>
            <button v-if="isMobile"
                @click="navToggle = !navToggle"
                class="nav-main__burger"
                :class="{ 'is-active': navToggle }"
                aria-label="menu"
                aria-expanded="false">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </button>
            <a href="/"><img src="/assets/images/logo-all-blue.png" /></a>
        </div>
        <div>
            <ul v-if="isDesktop">
                <li><router-link :to="{ name: 'aboutUs' }">About Us</router-link></li>
                <li><router-link :to="{ name: 'carriers' }">Carriers</router-link></li>
                <li><router-link :to="{ name: 'driveWithUs' }">Drive with Us</router-link></li>
            </ul>
            <ul class="nav-main__contact">
                <li><a href="tel:+1 502-614-4583"><o-icon icon="phone-alt"></o-icon>(502) 614 - 4583</a></li>
                <li><a href="mailto:inquiries@pbstransport.com"><o-icon icon="envelope"></o-icon>info@pbstransport.com</a></li>
            </ul>
        </div>
    </nav>
    <nav v-if="showSideNav" :class="sidenavClasses" v-click-outside="onClickOutside">
        <ul>
            <li><router-link :to="{ name: 'aboutUs' }">About Us</router-link></li>
            <li><router-link :to="{ name: 'carriers' }">Carriers</router-link></li>
            <li><router-link :to="{ name: 'driveWithUs' }">Drive with Us</router-link></li>
        </ul>
    </nav>
</template>
<script>
    import { ref, computed, watch, nextTick, onMounted, inject, watchEffect } from "vue";
    import { useElementRemWidth } from "../composables/useElementRemWidth";
    import vClickOutside from 'click-outside-vue3';
    
    export default {
        props: {},
        directives: {
            clickOutside: vClickOutside.directive
        },
        setup() {
            const $throttle = inject("$throttle");

            const navElement = ref(null);
            const navWidth = useElementRemWidth(navElement);
            const navToggle = ref(false);
            const shouldReset = ref(false);

            const sizeCategory = computed(() => {
                if (navWidth.value > 62) return "desktop";
                if (navWidth.value > 40) return "tablet";
                return "mobile";
            });

            const isDesktop = computed(() => {
                return sizeCategory.value === "desktop";
            });

            const isTablet = computed(() => {
                return sizeCategory.value === "tablet";
            });

            const isMobile = computed(() => {
                return sizeCategory.value === "mobile";
            });

            const showMainNav = computed(() => {
                if (isDesktop.value) return true;
                return false;
            });

            const showSideNav = computed(() => {
                if (isDesktop.value) return false;
                if (isTablet.value) return true;
                if (isMobile.value && navToggle.value) return true;
                return false;
            });

            const navClasses = computed(() => {
                return {
                    "nav-main": true,
                    "nav-main--desktop": isDesktop.value,
                    "nav-main--tablet": isTablet.value,
                    "nav-main--mobile": isMobile.value,
                    
                    //this is a hacky fix to a chrome bug with backdrop-filter
                    "nav-main--reset": shouldReset.value
                };
            });

            const sidenavClasses = computed(() => {
                return {
                    "nav-side": true,
                    "nav-side--desktop": isDesktop.value,
                    "nav-side--tablet": isTablet.value,
                    "nav-side--mobile": isMobile.value,
                    "nav-side--reset": shouldReset.value
                };
            });

            const innerComponentType = computed(() => {
                return isDesktop.value ? 'li' : 'o-dropdown-item';
            });

            const resetBackdrop = () => {
                shouldReset.value = true;
                setTimeout(()=>{ shouldReset.value = false; }, 10);
            };

            const onClickOutside = () => {
                if (navToggle.value) {
                    navToggle.value = false;
                }
            };

            const onResize = $throttle(() => {
                resetBackdrop();
                
            }, 100);

            watch(navWidth, (n, o) => {
                onResize();
            });

            onMounted( () => {
                resetBackdrop();
            });

            return {
                navToggle,
                navElement,
                navWidth,
                isDesktop,
                isTablet,
                isMobile,
                showSideNav,
                navClasses,
                sidenavClasses,
                innerComponentType,
                onClickOutside
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/abstracts/main.scss";

    //this is a hacky fix to a chrome bug with backdrop-filter
    nav.nav-main.nav-main--reset::before, nav.nav-side.nav-side--reset::before {
        z-index: 0;
    }

    nav.nav-main {
        position: sticky;
        top: 0;
        left: 0;

        z-index: 2;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: pxUnits(80);
        min-height: pxUnits(80);
        box-sizing: border-box;
        padding-left: pxUnits(18);
        padding-right: pxUnits(50);
        
        @include glassy-white;

        

        & > div {
            display: flex;
            flex-direction: row;

            &:nth-child(2) {
                column-gap: pxUnits(60);
            }

            & > a {
                display: flex;
                flex-direction: row;
                align-items: center;
                & img {
                    width: pxUnits(160);
                }
            }

            & > ul {
                display: flex;
            }

            & > ul:first-child {
                flex-direction: row;
                align-items: center;
                column-gap: pxUnits(15);

                & > li {
                    & > a {
                        @include navLink1;
                    }
                }
               
            }

            & > ul.nav-main__contact {
                flex-direction: column;
                align-items: flex-end;

                & > li > a {
                    @include navLink2;
                }
            }
        }

        &--desktop {
            margin-bottom: pxUnits(30);
        }

        &--tablet {
            height: pxUnits(54);
            min-height: pxUnits(54);
        }

        &--mobile {
            //margin-bottom: pxUnits(30);
            height: pxUnits(54);
            min-height: pxUnits(54);
            padding-right: pxUnits(18);
        }
    }

    nav.nav-side {
        z-index: 3;

        & > ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: pxUnits(15);
            //height: 2rem;

            & > li > a {
                @include navLink1;
            }
        }

        // &--desktop {
        //     @include glassy-white-filter(12px);

        //     position: fixed;
        //     top: pxUnits(80);
        //     background-color: rgba(#dbdee1, 0.8);
        // }

        &--tablet {
            @include glassy-white-filter(12px);

            position: sticky;
            top: pxUnits(54);
            width: 100%;
            margin-bottom: pxUnits(30);

            background-color: rgba(#dbdee1, 0.8);

            & > ul {
                flex-direction: row;
                align-items: center;
                justify-content: center;
                column-gap: pxUnits(15);
                padding: pxUnits(3) pxUnits(15);
                //height: 2rem;

                & > li > a {
                    @include navLink1;
                }
            }
        }

        &--mobile {
            @include glassy-white-filter(4px);

            position: sticky;
            top: pxUnits(54);
            width: 100%;
            margin-bottom: pxUnits(30);

            background-color: rgba(#eaecee, 0.8);

            &::before {
                z-index: 2;
            }

            & > ul {
                position: relative;
                z-index: 3;

                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: pxUnits(12);
                padding: pxUnits(15) pxUnits(15);
                //height: 2rem;

                & > li > a {
                    @include navLink1;
                }
            }
        }
    }
</style>
