<template>
	<div class="app-main" :class="{ 'app-main-mobile': mobileHeader }">
		<navbar></navbar>
		<main class="main" v-bind="$attrs">
			<router-view />
		</main>
		<footer class="footer">
			<nav class="footer__nav">
				<ul>
					<li><router-link :to="{ name: 'aboutUs' }">About Us</router-link></li>
					<li><router-link :to="{ name: 'carriers' }">Carriers</router-link></li>
					<li><router-link :to="{ name: 'driveWithUs' }">Drive with Us</router-link></li>
					<li><router-link :to="{ name: 'aboutUs', hash: '#contactUs' }">Contact Us</router-link></li>
				</ul>
			</nav>
			<div class="footer__address">
				<a href="/"><img src="/assets/images/logo-all-white.png" /></a>
				<address>
					4601 Jennings Ln, Louisville, KY 40218<br />
					(502) 614-4583<br />
					lauren@pbstransport.com
				</address>
			</div>
			<div class="footer__partnerships">
				<img class="partnership--smartway" src="/assets/images/smartWayLogo.jpg" />
				<img class="partnership--tia" src="/assets/images/TIAMemberButton.png" />
			</div>
		</footer>
	</div>
</template>
<script>
	import {
		ref,
		inject
	} from "vue";
	import { useRoute, useRouter } from "vue-router";
	import store from "@/store";
	import { navbar } from "@/components";
	export default {
		components: {
			"navbar": navbar
		},
		setup() {
			const $route = useRoute();
			const $router = useRouter();
			const $routes = $router.options.routes;
			const $confirm = inject("$confirm");

			const $http = inject("$http");
			const $moment = inject("$moment");
			const $oruga = inject("$oruga");

			const $success = inject("$success");
			const $error = inject("$error");

			const mobileHeader = ref(false);
			const hideSearch = ref(true);
			const searchOpen = ref(false);
			const navigationOpen = ref(false);
			const downloading = ref(false);
			const page = ref({});
			const user = ref(store.getters.user);
			const selected = ref(null);

			const boydenSearch = ref(null);

			return {
			};
		}
	};
</script>
<style lang="scss" scoped>
	@import "@/assets/styles/abstracts/main.scss";

	.app-main {
		position: relative;
		z-index: 0;

		display: flex;
		flex-direction: column;

		height: 100%;

		overflow-y: auto;

		& > .spacer {
			//flex-grow: 1;
		}
	}

	main.main {
		position: relative;
		padding-bottom: pxUnits(30);
		flex-grow:1;
	}

	footer.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: pxUnits(32);

		padding: pxUnits(43) 0;
		background-color: $pbs-slate;

		& > .footer__nav {
			display: flex;
			align-items: center;
			justify-content: center;
				
			& > ul {
				display: grid;
				grid-template-rows: auto auto auto;
				grid-template-columns: auto auto;
				row-gap: pxUnits(15);
				column-gap: pxUnits(60);

				width: fit-content;
				height: fit-content;
				
				& > li > a {
					@include navLink3;
				}
			}
		}

		& > .footer__address {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: pxUnits(15);

			& > a {
				line-height: 0;
			}

			& img {
				width: pxUnits(180);
			}

			& > address {
				@include address1;

				text-align: center;
			}
		}

		& > .footer__ellipsis {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: pxUnits(20);

			margin: auto;

			& > div {
				border-radius: pxUnits(14);
				width: pxUnits(28);
				height: pxUnits(28);
				background-color: #FFF;
			}
		}

		& > .footer__partnerships {
			display: flex;
			flex-direction: row;
			align-items: center;

			
			& > .partnership--tia {
				margin-left: pxUnits(22);
				width: pxUnits(100);
				transform: scale(1.5);
			}

			& > .partnership--smartway {
				width: pxUnits(160);
				height: auto;
				border: 6px solid #FFF;
				border-radius: 6px;
			}
			
		}

		@media screen and (min-width: 50rem) {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;

			padding-left: 0;
			padding-right: 0;

			& > .footer__ellipsis {
				display: none;
			}
		}

		@media screen and (min-width: 70rem) {
			//display: grid;
			//grid-template-columns: 1fr 1fr 1fr;
			//justify-content: center;
			padding-left: pxUnits(180);
			padding-right: pxUnits(180);
			column-gap: pxUnits(60);

			& > .footer__nav {
				justify-content: flex-end;
			}

			& > .footer__address {
				//margin: auto;
			}

			& > .footer__ellipsis {
				display: flex;
				flex-direction: row;
				margin: auto auto auto 0;
			}
		}
		
	}
</style>
