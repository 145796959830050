<template>
    <router-view />
</template>

<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");

            var refreshPromises = [];
            var refreshing = false;
            $http.interceptors.response.use(resp => {
                return resp;
            }, async (err) => {
                if (err.response.status >= 401 && err.response.headers["token-expired"] == "true" && !refreshing) {
                    refreshing = true;
                    return await new Promise((resolve, reject) => {
                        $http.post("/api/user/refresh", {})
                            .then(async (r) => {
                                if (r) {
                                    global.$store.dispatch("login", r.data);
                                    refreshing = false;
                                    resolve($http.request(err.config));
                                    refreshPromises = refreshPromises.map(async (config) => {
                                        return await $http.request(config);
                                    });
                                }
                                else {
                                    resolve(err.config);
                                }
                            })
                            .catch(e => {
                                console.error("failed to refresh.");
                                global.$store.dispatch("logout");
                                reject(e);
                            });
                    });
                }
                else if (refreshing) {
                    refreshPromises.push(err.config);
                }
                else {
                    return Promise.reject(err);
                }
            });


            return {


            }
        }
    }
</script>