<template>
    <section class="carriers">
        <!-- <h2><span class="blue">&bull;.: </span>Carriers Us<span class="blue"> :.&bull;</span></h2> -->
        <h2>Carriers</h2>
        <div  class="carriers__control">
            <div class="carriers__section">
                <p>We are more than just brokers. We are former dispatchers, drivers, fleet operations specialists, and warehouse managers. Our brokers are required to ride OTR and local for two weeks with one of our company drivers, because we never want to lose touch. Drivers and carriers are the backbone of our industry. We treat you as our partner, and we believe in building a brighter future for our industry with you.</p>
            </div>
        </div>
        <div class="carriers__buttons">
            <link-button href="https://pbsonboarding.rmissecure.com" class="carriers__button" fg="white" bg="black">Join Our Network</link-button>
        </div>
    </section>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject, reactive, watchEffect } from "vue";
import LinkButton from '../components/link-button.vue';
    export default {
        components: {LinkButton},
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");

            return {
                
            };
        }
    }

</script>
<style lang="scss" scoped>
    @import "@/assets/styles/abstracts/main.scss";

    section.carriers {
        @include card-shadow;
        
        display: flex;
        flex-direction: column;
        gap: pxUnits(60);

        margin: auto;
        max-width: 80rem;
        padding: pxUnits(60) pxUnits(60);

        background-color: rgba($pbs-snow, 0.87);
        //background: linear-gradient(219deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.05) 100%);

        & .carriers__control {
            display: flex;
            flex-direction: column;
            gap: pxUnits(35);

            margin: 0 pxUnits(-180);
            padding: pxUnits(30) pxUnits(180);

            background-color: #FFF;

            & p, & h3 {
                color: $text-black;
            }
        }

        & .carriers__section, & .carriers__contacts {
            display: flex;
            flex-direction: column;
            gap: pxUnits(60);

            & > span {
                display: flex;
                flex-direction: column;
                gap: pxUnits(35);

                //@include tile;
            }
        }

        & .carriers__contacts {
            flex-direction: column;
            gap: pxUnits(35);
        }

        & p {
            @include body2;
            color: $text-gray;
        }

        & h2 {
            //text-decoration: underline $pbs-blue;
            margin: -2rem 0;

            text-underline-offset: 0.3em;
            //text-align: center;
            @include heading1;
            color: $text-black;
        }

        & h3 {
            //text-decoration: underline $pbs-blue;
            @include heading2;

            white-space: nowrap;

            &:not(:first-of-type) {
                //margin-top: pxUnits(50);
            }
        }

        & .carriers__buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: pxUnits(15);
            margin: -2rem 0;
        }

        & .carriers__button {
            //@include card-shadow;
        }

        & .contacts {
            @include grid-minmax(pxUnits(300), 1fr);
            gap: pxUnits(50);

            & address {
                $indent: 0;

                margin-left: $indent;

                @include tile;

                font-style: normal;
                //line-height: 1.6em;

                //color: $text-light-gray;

                @include body6;

                & > b {
                    display: block;

                    margin-left: -$indent;
                    margin-bottom: pxUnits(6);

                    @include body4;
                    color: $text-black;
                }
                
                & > em {
                    display: block;
                    margin-top: -0.4em;
                    margin-bottom: 0.4em;
                    margin-left: -$indent;
                    font-size: 0.8em;
                    color: $text-black;
                    //margin-right: pxUnits(15);
                }
            }
        }

        & span.blue {
            user-select: none;
        }

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: pxUnits(-110);
            left: 0;
            height: calc(100% + pxUnits(110));
            width: 100%;

            background-image: url("/public/assets/images/CarriersPage.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        @media screen and (min-width: pxUnits(977)) {
            padding: pxUnits(60) pxUnits(180);
        }
    }
</style>