<template>
    <header class="jumbotron">
        <h2 class="jumbotron__heading">A Trucking Company Who <span class="blue">Cares</span></h2>
        <p class="jumbotron__body">Dependable Transport <span class="blue">•</span> Compassionate Team <span class="blue">•</span> Fulfilling Careers</p>
        <div class="jumbotron__links">
            <link-button-glass :to="{ name: 'driveWithUs' }">For Drivers</link-button-glass>
            <link-button-glass :to="{ name: 'carriers' }">For Carriers</link-button-glass>
        </div>
    </header>
    <section class="experience">
        <p>We are committed to serving our customers with safe, reliable and professional transport of their goods by truck. Our experienced trucking company with 99.98% on-time deliveries is looking for new carriers, customers and drivers.</p>
        <ul>
            <li><span class="blue">99.98%</span>On-time deliveries</li>
            <li><span class="blue">78 years</span>Experience</li>
            <li><span class="blue"><span class="small">over</span> 15,000,000</span>Miles driven</li>
        </ul>
    </section>
</template>
<script>
    import linkButtonGlass from "../link-button-glass.vue";
    export default {
        components: {
            linkButtonGlass
        },
        props: {

        },
        setup() {

            return {
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/abstracts/main.scss";

    header.jumbotron {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: pxUnits(18);

        margin-top: pxUnits(-110);
        height: pxUnits(612);
        padding: 0 pxUnits(18);
        background-image: url("/public/assets/images/TruckingCompanyWhoCares.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        & .jumbotron__heading {
            margin-top: pxUnits(80);
            color: #FFF;
            text-shadow: 0 pxUnits(0.5) pxUnits(8) rgba(0, 0, 0, 0.15);
            text-align: center;
        }

        & .jumbotron__body {
            color: #FFF;
            text-shadow: 0 pxUnits(0.5) pxUnits(8) rgba(0, 0, 0, 0.15);
            text-align: center;
        }

        & .jumbotron__links {
            display: flex;
            flex-direction: row;
            gap: pxUnits(30);
            margin-top: pxUnits(16);
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            mix-blend-mode: hard-light;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            //opacity: 0.8;
            // background: rgb(0,0,0);
            // background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 25%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
            z-index: 0;
        }

        & > * {
            z-index: 1;
        }
    }

    section.experience {
        background-color: #FFF;

        & > p {
            @include body3;
            padding: pxUnits(50) pxUnits(60);
            background-color: $pbs-blue;
            text-align: center;
            color: #FFF;
        }

        & > ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: pxUnits(15);

            padding: pxUnits(35) pxUnits(60);

            & > li {
                @include body4;

                display: flex;
                flex-direction: column;
                align-items: center;

                & > span.blue {
                    @include heading4;
                }

                &:nth-child(1) {
                    align-self: flex-start;
                }
                &:nth-child(3) {
                    align-self: flex-end;
                }
            }

        }

        @media screen and (min-width: 40rem) {
            & > p {
                padding-top: pxUnits(40);
                padding-bottom: 0;
                background-color: transparent;
                color: $text-black;
            }
            
            & > ul {
                flex-direction: row;
                justify-content: space-evenly;
                & > li {
                    &:nth-child(1) {
                        align-self: unset;
                    }
                    &:nth-child(3) {
                        align-self: unset;
                    }
                }
            }
        }

        @media screen and (min-width: 61.21rem) {
            & > p {
                padding-left: pxUnits(180);
                padding-right: pxUnits(180);
            }
        }
    }
</style>