<template>
    <section class="about">
        <!-- <h2><span class="blue">&bull;.: </span>About Us<span class="blue"> :.&bull;</span></h2> -->
        <h2>About Us</h2>
        <div  class="about__control">
            <div class="about__section">
                <h3>Who We Are</h3>
                <span>
                    <p>
                        PBS Transport is a family-owned and operated trucking company that specializes in hauling refrigerated goods, including produce and meats. We’ve been in the transportation industry for over 78 years, and acquired our trucking authority to expand our services in 2014. We believe in treating our employees and customers like family, and that philosophy is the core of everything we do.
                    </p>
                </span>
            </div>
            <div class="about__section">
                <h3>What We Do</h3>
                <span>
                    <p>
                        Our sister company, Paul's Fruit Markets, Inc, has been sourcing the finest products for their gourmet grocery stores in Louisville, KY since 1945. Our combined expertise and knowledge in hauling refrigerated goods enables us to provide exceptional service to our customers.
                    </p>
                </span>
            </div>
            <div class="about__buttons">
                <link-button :to="{ name: 'driveWithUs' }" class="about__button" fg="black" bg="white">Drive with us</link-button>
            </div>
        </div>
        
        <div id="contactUs" class="about__contacts">
            <h3>Contact Us</h3>
            <div class="contacts">
                <address>
                    <b>Emily Weller</b>
                    <em>Transportation Manager</em>
                    <a href="tel:+15026144583">(502) 614 4583</a> <br />
                    <a href="mailto:emily@pbstransport.com">emily@pbstransport.com</a>
                </address>
                <address>
                    <b>Shea Hall</b>
                    <em>Freight Planning & Dispatch</em>
                    <a href="tel:+15026144583">(502) 614 4583</a> <br />
                    <a href="mailto:freight@pbstransport.com">freight@pbstransport.com</a>
                </address>
                <address>
                    <b>Pam Grantz</b>
                    <em>Dispatch & Scheduling</em>
                    <a href="tel:+15026144583">(502) 614 4583</a> <br />
                    <a href="mailto:dispatch@pbstransport.com">dispatch@pbstransport.com</a>
                </address>
                <address>
                    <b>Tina Jones</b>
                    <em>AP & AR</em>
                    <a href="tel:+15026144583">(502) 614 4583</a> <br />
                    <a href="mailto:Pbsaccounting@pbstransport.com">Pbsaccounting@pbstransport.com</a>
                </address>
                <address>
                    <b>James Schuengel</b>
                    <em>Shop Foreman</em>
                    <a href="mailto:shop@pbstransport.com">shop@pbstransport.com</a>
                </address>
                <address>
                    <b>Bernardo Ramirez-Escamilla</b>
                    <em>Lead Mechanic</em>
                    <a href="mailto:shop@pbstransport.com">shop@pbstransport.com</a>
                </address>
            </div>
        </div>
    </section>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject, reactive, watchEffect } from "vue";
import LinkButton from '../components/link-button.vue';
    export default {
        components: {LinkButton},
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");

            return {
                
            };
        }
    }

</script>
<style lang="scss" scoped>
    @import "@/assets/styles/abstracts/main.scss";

    section.about {
        @include card-shadow;
        
        display: flex;
        flex-direction: column;
        gap: pxUnits(60);

        margin: auto;
        max-width: 80rem;
        padding: pxUnits(60) pxUnits(60);

        background-color: rgba(246, 248, 252, 0.87);
        //background: linear-gradient(219deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.05) 100%);

        & .about__control {
            display: flex;
            flex-direction: column;
            gap: pxUnits(35);

            margin: 0 pxUnits(-180);
            padding: pxUnits(30) pxUnits(180);

            background-color: $pbs-blue;

            & p, & h3 {
                color: #FFF;
            }
        }

        & .about__section, & .about__contacts {
            display: flex;
            flex-direction: row;
            gap: pxUnits(60);

            & > span {
                display: flex;
                flex-direction: column;
                gap: pxUnits(35);

                //@include tile;
            }
        }

        & .about__contacts {
            flex-direction: column;
            gap: pxUnits(35);
        }

        & p {
            @include body2;
            color: $text-gray;
        }

        & h2 {
            //text-decoration: underline $pbs-blue;
            margin: -2rem 0;

            text-underline-offset: 0.3em;
            //text-align: center;
            @include heading1;
        }

        & h3 {
            //text-decoration: underline $pbs-blue;
            @include heading2;

            white-space: nowrap;

            &:not(:first-of-type) {
                //margin-top: pxUnits(50);
            }
        }

        & .about__buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: pxUnits(15);
            margin-bottom: pxUnits(-60);
        }

        & .about__button {
            position: relative;
            top: pxUnits(-12);
            //margin: 0 auto;
            
            outline: pxUnits(10) solid rgba($pbs-blue, 0.4);
        }

        & .contacts {
            @include grid-minmax(pxUnits(300), 1fr);
            gap: pxUnits(50);

            & address {
                $indent: 0;

                margin-left: $indent;

                @include tile;

                font-style: normal;
                //line-height: 1.6em;

                //color: $text-light-gray;

                @include body6;

                & > b {
                    display: block;

                    margin-left: -$indent;
                    margin-bottom: pxUnits(6);

                    @include body4;
                    color: $text-black;
                }
                
                & > em {
                    display: block;
                    margin-top: -0.4em;
                    margin-bottom: 0.4em;
                    margin-left: -$indent;
                    font-size: 0.8em;
                    color: $text-black;
                    //margin-right: pxUnits(15);
                }
            }
        }

        & span.blue {
            user-select: none;
        }

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: pxUnits(-110);
            left: 0;
            height: calc(100% + pxUnits(110));
            width: 100%;

            background-image: url("/public/assets/images/AboutUs.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        @media screen and (min-width: pxUnits(977)) {
            padding: pxUnits(60) pxUnits(180);
        }
    }
</style>
