<template>
    <section :class="panelClasses" ref="panelElement">
        <div class="info-panel__image" :style="'background-image: url(\'' + src + '\');'"></div>
        <div class="info-panel__body">
            <h3>{{title}}</h3>
            <p><slot></slot></p>
            <!-- <link-button :to="to" :fg="buttonForeground" :bg="buttonBackground">Learn more</link-button> -->
        </div>
    </section>
</template>
<script>
    import { ref, computed, watch, nextTick, onMounted, inject, watchEffect } from "vue";
    import { useElementRemWidth } from "../../composables/useElementRemWidth";
import linkButton from '../link-button.vue';
    
    export default {
	//components: { linkButton },
        props: {
            direction: {
                type: String,
                default: "right"
            },
            src: {
                type: String,
                default: ""
            },
            title: {
                type: String,
                default: ""
            },
            to: {
                type: [Object, String],
                default: "/"
            },
            background: {
                type: String,
                default: "white"
            }
        },
        setup(props, context) {

            const panelElement = ref(null);
            const panelWidth = useElementRemWidth(panelElement);

            const sizeCategory = computed(() => {
                if (panelWidth.value > 100) return "wide";
                if (panelWidth.value > 75) return "desktop";
                if (panelWidth.value > 60) return "tablet";
                return "mobile";
            });

            const isWide = computed(() => {
                return sizeCategory.value === "wide";
            });

            const isDesktop = computed(() => {
                return sizeCategory.value === "desktop";
            });

            const isTablet = computed(() => {
                return sizeCategory.value === "tablet";
            });

            const isMobile = computed(() => {
                return sizeCategory.value === "mobile";
            });

            const panelClasses = computed(() => {
                const colorClass = `info-panel--${props.background.toLowerCase()}`;
                const classObj = {
                    "info-panel": true,
                    "info-panel--wide": isWide.value,
                    "info-panel--desktop": isDesktop.value,
                    "info-panel--tablet": isTablet.value,
                    "info-panel--mobile": isMobile.value,
                    "info-panel--right": props.direction.toLowerCase() === "right",
                    "info-panel--left": props.direction.toLowerCase() === "left"
                };
                classObj[colorClass] = true
                return classObj;
            });

            const buttonBackground = computed(() => {
                switch (props.background.toLowerCase()) {
                    case "blue":
                        return "white";
                    case "white":
                        return "blue";
                }
                return "blue";
            });

            const buttonForeground = computed(() => {
                switch (props.background.toLowerCase()) {
                    case "blue":
                        return "black";
                    case "white":
                        return "white";
                }
                return "white";
            });

            return {
                panelElement,
                panelWidth,
                isWide,
                isDesktop,
                isTablet,
                isMobile,
                panelClasses,
                buttonBackground,
                buttonForeground
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/abstracts/main.scss";

    section.info-panel {
        display: flex;
        flex-direction: row;
        position: relative;

        & > .info-panel__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: pxUnits(35);
            min-height: pxUnits(520);
            max-width: pxUnits(860);
            padding: 0 pxUnits(180);

            & > h3 {
                text-align: center;
            }

            & > p {
                @include body2;
            }
        }

        & > .info-panel__image {
            width: 100%;
            height: auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: $pbs-blue;
        }

        &--right {
            flex-direction: row-reverse;
        }

        &--blue > .info-panel__body {
            background-color: $pbs-blue;
            color: #FFF;
        }

        &--snow > .info-panel__body {
            background-color: $pbs-snow;
            color: $text-black;
        }
        
        &--wide {
            &.info-panel--white {
                background-color: $pbs-snow;
            }

            &.info-panel--blue {
                background-color: $pbs-blue;
            }

            &.info-panel--right {
                & > .info-panel__image {
                    -webkit-mask-image: -webkit-linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0));
                    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0));
                }
            }
            &.info-panel--left {
                & > .info-panel__image {
                    //margin-left: pxUnits(-100);
                    -webkit-mask-image: -webkit-linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0));
                    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0));
                }
            }
        }

        &--tablet {
            & > .info-panel__body {
                padding: 0 pxUnits(180);
            }
        }

        &--mobile {
            & > .info-panel__body {
                padding: 0 pxUnits(60);
            }
        }

        &--tablet, &--mobile {

            &.info-panel--blue > .info-panel__body {
                background-color: rgba($pbs-blue, 0.87)
            }

            &.info-panel--white > .info-panel__body {
                background-color: rgba($pbs-snow, 0.87)
            }
            
            & > .info-panel__body {
                position: relative;
                z-index: 1;
                max-width: unset;
                width: 100%;
            }

            & > .info-panel__image {
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        // &--desktop {
        //     &.info-panel--blue {
        //         background-color: $pbs-blue;
        //     }

        //     &.info-panel--right {
        //         & > .info-panel__image {
        //             -webkit-mask-image: -webkit-linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
        //             mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
        //         }
        //     }
        //     &.info-panel--left {
        //         & > .info-panel__image {
        //             //margin-left: pxUnits(-100);
        //             -webkit-mask-image: -webkit-linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
        //             mask-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
        //         }
        //     }
        // }
    }
</style>