import { ref, Ref, readonly } from 'vue';

let mutationObserver: MutationObserver;
const rootFontSize = ref(0);

export function useRootFontSize(): Readonly<Ref<number>> {
    rootFontSize.value = parseFloat(window.getComputedStyle(document.documentElement).fontSize);

    if (!mutationObserver) {
        mutationObserver = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.target === document.documentElement && mutation.attributeName === "style") {
                    const newFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
                    if (newFontSize !== rootFontSize.value) {
                        rootFontSize.value = newFontSize;
                    }
                }
            }
        });

        mutationObserver.observe(document.documentElement, { attributes: true });
    }

    return readonly(rootFontSize);
}