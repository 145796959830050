<template>
    <section :class="whyPbsClasses" ref="whyPbsElement">
        <div class="why-pbs__statement">
            <h3>Why PBS?</h3>
            <p>At PBS, we prioritize the safety, reliability, and professionalism of our trucking services to ensure that your goods are transported with the utmost care and efficiency.</p>
        </div>
        <div class="why-pbs__perks">
            <div class="why-pbs__perk">
                <o-icon icon="users"></o-icon>
                <div>
                    <h4>Low driver turnover</h4>
                    <p>Our supportive work environment leads to low driver turnover rates.</p>
                </div>
            </div>
            <div class="why-pbs__perk">
                <o-icon icon="handshake"></o-icon>
                <div>
                    <h4>Family-owned &amp; operated</h4>
                    <p>As a family-owned business, we prioritize creating an inclusive workplace.</p>
                </div>
            </div>
            <div class="why-pbs__perk">
                <o-icon icon="clock"></o-icon>
                <div>
                    <h4>Flexible hours</h4>
                    <p>We offer flexible hours to accommodate our drivers' needs.</p>
                </div>
            </div>
            <div class="why-pbs__perk">
                <div class="icon-bundle">
                    <o-icon icon="star"></o-icon>
                    <o-icon icon="star"></o-icon>
                    <o-icon icon="star"></o-icon>
                </div>
                <div>
                    <h4>Satisfied drivers</h4>
                    <p>We prioritize our drivers' satisfaction and provide the resources and support they need.</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { ref, computed, watch, nextTick, onMounted, inject, watchEffect } from "vue";
    import { useElementRemWidth } from "../../composables/useElementRemWidth";
    
    export default {
        props: {
            direction: {
                type: String,
                default: "right"
            },
            src: {
                type: String,
                default: ""
            },
            title: {
                type: String,
                default: ""
            },
            to: {
                type: [Object, String],
                default: "/"
            },
            background: {
                type: String,
                default: "white"
            }
        },
        setup(props, context) {

            const whyPbsElement = ref(null);
            const elementWidth = useElementRemWidth(whyPbsElement);

            const sizeCategory = computed(() => {
                if (elementWidth.value > 95) return "wide";
                if (elementWidth.value > 80) return "desktop";
                if (elementWidth.value > 45) return "tablet";
                return "mobile";
            });

            const isWide = computed(() => {
                return sizeCategory.value === "wide";
            });

            const isDesktop = computed(() => {
                return sizeCategory.value === "desktop";
            });

            const isTablet = computed(() => {
                return sizeCategory.value === "tablet";
            });

            const isMobile = computed(() => {
                return sizeCategory.value === "mobile";
            });

            const whyPbsClasses = computed(() => {
                const colorClass = `why-pbs--${props.background.toLowerCase()}`;
                const classObj = {
                    "why-pbs": true,
                    "why-pbs--wide": isWide.value,
                    "why-pbs--desktop": isDesktop.value,
                    "why-pbs--tablet": isTablet.value,
                    "why-pbs--mobile": isMobile.value,
                    "why-pbs--right": props.direction.toLowerCase() === "right",
                    "why-pbs--left": props.direction.toLowerCase() === "left"
                };
                classObj[colorClass] = true
                return classObj;
            });

            const buttonBackground = computed(() => {
                switch (props.background.toLowerCase()) {
                    case "blue":
                        return "white";
                }
                return "blue"
            });

            return {
                whyPbsElement,
                elementWidth,
                isWide,
                isDesktop,
                isTablet,
                isMobile,
                whyPbsClasses,
                buttonBackground
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/abstracts/main.scss";

    section.why-pbs {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: pxUnits(50);

        padding: pxUnits(50) pxUnits(43);

        & > .why-pbs__statement {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: pxUnits(10);

            & > h3 {
                @include heading3;
                color: $pbs-blue;
            }

            & > p {
                @include body4;
                text-align: center;
            }
        }

        & > .why-pbs__perks {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: pxUnits(35);

            & > .why-pbs__perk {

                display: flex;
                flex-direction: row;
                gap: pxUnits(10);

                margin-bottom: auto;
                width: pxUnits(286);

                & h4, .icon {
                    @include body3-short;
                }

                & .icon-bundle {
                    position: relative;
                    bottom: 0.3em;
                    & .icon {
                        width: pxUnits(20);
                        font-size: 0.7em;

                        &:nth-child(2) {
                            position: absolute;
                            top: 1em;
                            right: -0.6em;
                        }

                        &:nth-child(3) {
                            position: absolute;
                            top: 1em;
                            right: 0.6em;
                        }
                    }
                }

                & p {
                    @include body6;
                }
            }
        }

        &--tablet {
            & > .why-pbs__perks {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(2, auto);
                
                
                gap: pxUnits(35);

                & > .why-pbs__perk:nth-child(3) {
                    flex-basis: 100%;
                }
            }
        }

        &--desktop, &--wide {
            & > .why-pbs__perks {
                flex-direction: row;
            }
        }
    }
</style>