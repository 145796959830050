import { App, inject } from "vue";
import Axios from 'axios';
import store from '@/store';

export function refreshToken(auto_logout) {
    const refreshToken = localStorage.getItem("REFRESH_TOKEN");
    return Axios.post("/api/user/refresh", { token: refreshToken })
        .then(function (response) {
            store.dispatch("refresh", response.data);
        })
        .catch(function (err) {
            if (auto_logout) {
                setTimeout(() => { store.dispatch("logout"); }, 5000);
            }
            console.error(err);
            return Promise.reject(err);
        });
}