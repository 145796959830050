<template>
    <router-link class="link-button" :to="to"><slot></slot></router-link>
</template>
<script>
    import { ref, computed, watch, onMounted } from "vue";
    export default {
        props: {
            to: {
                type: [Object, String],
                default: "/"
            }
        },
        setup() {
            return {

            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/abstracts/main.scss";

    a.link-button {
        @include glassy-button;
        
        width: pxUnits(172);
        text-align: center;
    }
</style>