<template>
    <router-link v-if="!href" :class="linkButtonClasses" :to="to"><slot></slot></router-link>
    <a v-else :class="linkButtonClasses" :href="href"><slot></slot></a>
</template>
<script>
    import { ref, computed, watch, onMounted } from "vue";
    export default {
        props: {
            to: {
                type: [Object, String],
                default: "/"
            },
            href: {
                type: String,
                default: undefined
            },
            fg: {
                type: String,
                default: "blue"
            },
            bg: {
                type: String,
                default: "white"
            },
            outlined: {
                type: Boolean,
                default: false
            },
            glassy: {
                type: Boolean,
                default: false
            }
        },
        setup(props, context) {
            const linkButtonClasses = computed(()=>{
                const fgClass = `link-button--fg-${props.fg.toLowerCase()}`;
                const bgClass = `link-button--bg-${props.bg.toLowerCase()}`;
                const classObj = { 
                    "link-button": true,
                    "link-button--outlined": props.outlined,
                    "link-button--glassy": props.glassy
                };
                classObj[fgClass] = true;
                classObj[bgClass] = true;
                return classObj;
            });

            return {
                linkButtonClasses
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/styles/abstracts/main.scss";

    a.link-button {
        text-align: center;
    }

    a.link-button.link-button--outlined, a.link-button.link-button--glassy {
        @include button1-short;
    }

    a.link-button.link-button--glassy {
        &.link-button--fg-white {
            @include glassy-button(#FFF);
        }
        &.link-button--fg-blue {
            @include glassy-button($pbs-blue);
        }
    }

    a.link-button:not(.link-button--glassy) {
        &.link-button--fg-white {
            &.link-button--bg-blue {
                @include solid-button(#FFF, $pbs-blue);
            }
            &.link-button--bg-white {
                @include solid-button(#FFF, #FFF);
            }
             &.link-button--bg-black {
                @include solid-button(#FFF, $text-black);
            }
            &.link-button--outlined {
                border: 2.5px solid #FFF;
            }
        }
        &.link-button--fg-blue {
            &.link-button--bg-blue {
                @include solid-button($pbs-blue, $pbs-blue);
            }
            &.link-button--bg-white {
                @include solid-button($text-black, #FFF);
            }
            &.link-button--outlined {
                border: 2.5px solid $pbs-blue;
            }
        }
        &.link-button--fg-black,
        &.link-button--fg-slate {
            &.link-button--bg-blue {
                @include solid-button($text-black, $pbs-blue);
            }
            &.link-button--bg-white {
                @include solid-button($text-black, #FFF);
            }
            &.link-button--outlined {
                border: 2.5px solid $text-black;
            }
        }
    }
</style>