<template>
    <jumbotron></jumbotron>
    <info-panel title="Career Options" background="blue" src="/assets/images/CareerOptions.jpg" direction="right">
        Our company offers a variety of career options to suit different driving preferences and goals. We have over-the-road (OTR) driving for those who want to travel long distances, home nightly positions for drivers who want to be home every night, and dedicated routes for those who want to work on specific routes consistently.
    </info-panel>
    <info-panel title="State-of-the-art Equipment" background="white" src="/assets/images/StateOfTheArtEquipment.jpg" direction="left">
        We prioritize the safety and comfort of our drivers. Our sleeper trucks are equipped with a lounge and remote-controlled refrigeration units so drivers can relax and enjoy their downtime while their cargo stays cool.
    </info-panel>
    <info-panel title="Driver Training" background="blue" src="/assets/images/DriverTraining.jpg" direction="right">
        Our local partners provide comprehensive training programs to equip our drivers with comprehensive information on safety protocols and equipment operation, so you can feel confident on the road.
    </info-panel>
    <why-pbs></why-pbs>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject, reactive, watchEffect } from "vue";
    import { useElementRemWidth } from "../composables/useElementRemWidth";
    import jumbotron from '../components/home/jumbotron.vue';
    import InfoPanel from '../components/home/infoPanel.vue';
    import WhyPbs from '../components/home/whyPbs.vue';
    export default {
        components: { jumbotron, InfoPanel, WhyPbs },
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");

            return {
                
            };
        }
    }

</script>