<template>
    <section class="drive">
        <h2>Drive With Us<span class="blue">.</span></h2>
        
        <div class="quotes">
            <p class="quote">
                <q>The freedom you will get here is like nowhere else in the industry.<span class="quotee">Greg Viergutz</span></q>
            </p>
            
            <p class="quote">
                <q>Transportation is key, and knowing I am part of bringing food and goods to people makes me proud to be a part of PBS Transport.<span class="quotee">Tony Keys</span></q>
            </p>

            <p class="quote">
                <q>I like the family atmosphere of the small company; Mike takes care of his drivers like no one ever has for me. You can tell he genuinely cares.<span class="quotee">Dwayne Snow</span></q>
            </p>
        </div>

        <div class="perks">
            <h3>Over the Road</h3>
            <p>We run Florida, Texas, New Mexico, Arizona, California, Midwest, NO EAST COAST. Nothing east of Ohio and nothing north of Virginia.</p>
            <p>OTR Performance Mileage Bonus: .01-0.035 cents per mile.</p>
            <p>OTR Drivers earn .60-.68 cents per mile. Earn up to 100k a year! Pay Per Mile based on experience.</p>
            <h3>Dedicated</h3>
            <p>If you like consistency, we have several lanes that will fit.</p>
            <h3>Regional</h3>
            <p>Home every night. Guaranteed pay and per mile opportunities.</p>
            <p>Regional is a 300 mile radius of Louisville, KY out and back with no fixed schedule or days.</p>
            <p>Regional drivers earn $1300 a week salary!</p>
        </div>
        
        <!-- <h3>Apply Now</h3> -->
        <iframe src="https://www.formpl.us/form/4893297160617984" class="application-form"></iframe>
        <!-- <div class="application-form">
            <o-field label="First name" :variant="!(v$.firstName.$dirty && v$.firstName.$invalid) ? '' : 'danger'">
                <o-input v-model="form.firstName" placeholder="Jay"></o-input>
                <template #message>
                    <template v-for="error in v$.firstName.$errors">{{error.$message}}</template>
                </template>
            </o-field>
            <o-field label="Last name" :variant="!(v$.lastName.$dirty && v$.lastName.$invalid) ? '' : 'danger'">
                <o-input v-model="form.lastName" placeholder="Doe"></o-input>
                <template #message>
                    <template v-for="error in v$.lastName.$errors">{{error.$message}}</template>
                </template>
            </o-field>
            <o-field label="Email" :variant="!(v$.email.$dirty && v$.email.$invalid) ? '' : 'danger'">
                <o-input v-model="form.email" placeholder="jdoe@example.com"></o-input>
                <template #message>
                    <template v-for="error in v$.email.$errors">{{error.$message}}</template>
                </template>
            </o-field>
            <o-field label="Phone" :variant="!(v$.phone.$dirty && v$.phone.$invalid) ? '' : 'danger'">
                <o-input v-model="form.phone" placeholder="(555)555-5555"></o-input>
                <template #message>
                    <template v-for="error in v$.phone.$errors">{{error.$message}}</template>
                </template>
            </o-field>
            <o-field label="ZIP code" :variant="!(v$.zip.$dirty && v$.zip.$invalid) ? '' : 'danger'">
                <o-input v-model="form.zip" placeholder="12345"></o-input>
                <template #message>
                    <template v-for="error in v$.zip.$errors">{{error.$message}}</template>
                </template>
            </o-field>
            <o-field label="Resume (Optional)">
                <o-upload v-model="form.file">
                    <o-button tag="a">
                        <o-icon icon="upload"></o-icon>
                        <span>Click to upload</span>
                    </o-button>
                    <span class="file-name" v-if="form.file">
                    {{ form.file.name }}
                    </span>
                </o-upload>
            </o-field>
        </div> -->
        <!-- <o-button class="submit-button" iconLeft="paper-plane" variant="primary" @click="v$.$touch">Submit</o-button> -->
    </section>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject, reactive, watchEffect } from "vue";
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers } from '@vuelidate/validators'
    import LinkButton from '../components/link-button.vue';
    export default {
        components: { },
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $moment = inject("$moment");
            const $success = inject("$success");
            const $error = inject("$error");

            const form = ref({
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                zip: "",
                file: null
            });

            const isPhoneNumber = (s) => {
                return !helpers.req(s) || [10, 11].includes(s.match(/\d/g).length);
            }

            const isZip = (s) => {
                return !helpers.req(s) || s.match(/\d/g).length === 5;
            }

            const rules = {
                firstName: { required }, // Matches state.firstName
                lastName: { required }, // Matches state.lastName
                email: { required, email },
                phone: { required, isPhoneNumber: helpers.withMessage("Value is not a valid phone number", isPhoneNumber) },
                zip: { required, isZip: helpers.withMessage("Value is not a valid ZIP code", isZip) }
            }

            const v$ = useVuelidate(rules, form)

            return {
                form,
                v$
            };
        }
    }

</script>
<style lang="scss" scoped>
    @import "@/assets/styles/abstracts/main.scss";

    span.blue {
        //display: none;
    }

    section.drive {
        @include card-shadow;
        
        display: flex;
        flex-direction: column;
        gap: pxUnits(35);

        margin: auto;
        max-width: 80rem;

        $padding: pxUnits(60);
        padding: $padding $padding;

        background-color: rgba(246, 248, 252, 0.93);
        //color: #fff;

        & > iframe {
            margin-left: $padding * -0.5;
            margin-right: $padding * -0.5;

            border-radius: 14px;

            height: pxUnits(1100);
        }

        & .perks {
            display: flex;
            flex-direction: column;
            gap: pxUnits(35);

            background-color: $pbs-slate;
            margin: 0 pxUnits(-180);
            padding: pxUnits(50) pxUnits(180);
            color: #FFF;

            //margin-top: pxUnits(15);

            & > h3 {
                $border-width: pxUnits(4);
                margin-left: -2rem;
                border-right: $border-width solid #FFF;
                border-bottom: $border-width solid #FFF;
                width: fit-content;
                padding: 0.1rem 1rem;
                box-shadow: $border-width $border-width 0 0 rgba($pbs-slate, 1),
                            #{$border-width * 1.5} #{$border-width * 1.5} 0 0 rgba(#FFF, 0.2);
            }
        }

        & .quotes {
            display: flex;
            flex-direction: column;
            gap: pxUnits(80);

            margin-top: pxUnits(15);

            //margin: 0 pxUnits(-180);
            //padding: pxUnits(35) pxUnits(180);

            //background-color: $pbs-blue;
        }

        & .application-form {
            @include grid-minmax(20rem, 1fr);
            gap: 0 pxUnits(20);
        }

        & .submit-button {
            width: 100%;
            max-width: 10rem;
            margin: 0 auto;
        }

        & p {
            @include body2;

            &.quote {

                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                gap: pxUnits(50) pxUnits(30);

                margin-bottom: pxUnits(20);

                //color: #FFF;

                & > q {
                    position: relative;

                    @include tile(#FFF);
                    display: block;

                    padding-bottom: pxUnits(15);
                    max-width: 47rem;

                    box-shadow: 0.6rem 0.6rem 0 1.5rem rgba($pbs-blue, 0.87),
                                -0.6rem -0.6rem 0 1.5rem rgba(#fff, 0.87),
                                0.2em 0.4em 1.4em 0.6em rgb(0 0 0);

                    font-style: oblique;
                    color: $text-black;

                    & > span.quotee {
                        @include small-text;

                        position: absolute;
                        right: 7rem;
                        bottom: 0.7rem;

                        display: block;
                        width: 0;
                        height: 0;
                        
                        text-align: left;
                        white-space: nowrap;

                        font-style: normal;
                        font-size: 0.7em;

                        &::before {
                            content: " —";
                            //color: $pbs-blue;
                        }
                    }

                    &::before, &::after {
                        display: inline-block;
                        //color: $pbs-blue;
                    }

                    &::before {
                        transform: scale(2) translate(-0.3em, 0.1em);
                    }

                    &::after {
                        transform: scale(2) translate(0.1em, 0.1em);
                    }
                }
            }

            &:nth-child(even) {
                align-items: flex-end;
            }
        }

        & h2 {
            //text-decoration: underline $pbs-blue;
            margin: -2rem 0 0 0;
            text-underline-offset: 0.3em;
            text-align: left;
        }

        & .drive__buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: pxUnits(15);
        }

        & .drive__button {
            position: relative;
            top: pxUnits(10);
            //margin: 0 auto;
        }

        & span.blue {
            user-select: none;
        }

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: pxUnits(-110);
            left: 0;
            height: calc(100% + pxUnits(110));
            width: 100%;

            background-image: url("/public/assets/images/freightliner2.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        @media screen and (min-width: pxUnits(977)) {
            padding: pxUnits(60) pxUnits(180);
        }
    }
</style>