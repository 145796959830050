import { createStore } from 'vuex'
import Axios from 'axios';

export default createStore({
    state: {
        token: localStorage.getItem("AUTH_TOKEN"),
        refreshToken: localStorage.getItem("REFRESH_TOKEN"),
        user: JSON.parse(localStorage.getItem("USER") || "{}")
    },
    mutations: {
        SET_USER(state, params) {
            if (params) {
                state.token = params.Token;
                state.refreshToken = params.RefreshToken;
                state.user = params;

                localStorage.setItem("AUTH_TOKEN", params.Token);
                localStorage.setItem("REFRESH_TOKEN", params.RefreshToken);
                localStorage.setItem("USER", JSON.stringify(params));
            }
            else {
                state.token = null;
                state.refreshToken = null;
                state.user = {};

                localStorage.removeItem("AUTH_TOKEN");
                localStorage.removeItem("REFRESH_TOKEN");
                localStorage.removeItem("USER");
            }
        }
    },
    actions: {
        login: ({ commit, state }, params) => {
            commit("SET_USER", params);
            if (params) {
                Axios.interceptors.request.use(config => { config.headers["Authorization"] = "Bearer " + params.Token; return config; });
            }
        },
        logout: ({ commit, state }) => {
            commit("SET_USER", null);
        }
    },
    modules: {
    },
    getters: {
        user: state => {
            return state.user;
        },
        isLoggedIn: state => {
            return !!state.token;
        }
    }
})
